module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140,"quality":90,"linkImagesToOriginal":false,"showCaptions":["title"],"markdownCaptions":true,"sizeByPixelDensity":false,"backgroundColor":"white","withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://tobiaskaechele.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tobias C. Kaechele","short_name":"Tobias C. Kaechele","start_url":"/","background_color":"#f7f0eb","theme_color":"#FF1050","display":"standalone","icon":"src/assets/icon.png","icons":[{"src":"/images/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/images/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/images/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/images/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/images/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/images/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/images/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/images/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"83ae725c251b49f50f2c074ac5df1380"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
